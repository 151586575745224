function alerta(tipo, id) {
    tipo.value = document.getElementById('contenido' + id).textContent;
    console.log(id);
}


function agregarParrafo() {
    document.getElementById('vistaPrevia').innerHTML =
        document.getElementById('vistaPrevia').innerHTML +
        "<p onclick='alerta(parrafoEditar )' title='haga click en el texto para modificar' id='contenido" + id + "'>" + document.crearArt.parrafo.value + "</p>";
    document.crearArt.parrafo.value = "";
}

function t(id) {
    //<acronym title="texto que va a aparecer escrito en la etiqueta" lang="idioma">PALABRA QUE LLEVA LA ETIQUETA </acronym>
    document.getElementById('vistaPrevia').innerHTML =
        document.getElementById('vistaPrevia').innerHTML +
        "<h2 onclick='alerta(tituloEditar," + id + ")'  title='haga click en el texto para modificar' lang='spanish' id='contenido" + id + "'>" + document.crearArt.titulo.value + "</h2>";
    document.crearArt.titulo.value = "";

}